import { Box, Heading, Text, UnorderedList, ListItem } from '@chakra-ui/react';

function Policy() {
    return (
        <Box p={4}>
            <Heading as="h2" size="xl" mb={4}>Privacy Policy</Heading>
            <Text mb={4}>Last updated: August 29, 2024</Text>
            <Text mb={4}>
                This Privacy Policy outlines our policies and procedures regarding the collection, use, and disclosure of your information when you use our Service. It also explains your privacy rights and how the law protects you.
            </Text>
            <Text mb={4}>
                We are committed to protecting your privacy. We only use your Personal Data to provide and enhance the Service. By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.
            </Text>
            <Heading as="h2" size="lg" mt={6} mb={4}>Key Principles:</Heading>
            <UnorderedList mb={4}>
                <ListItem><strong>No Information Sharing:</strong> We do not share your information with third parties. Your data is solely used internally to improve user experience and Service quality.</ListItem>
                <ListItem><strong>Transparent Payments:</strong> Any payments you make through our Service will not be labeled as related to adult transactions on your bank statements. There are no hidden fees associated with our Service.</ListItem>
            </UnorderedList>
            <Heading as="h2" size="lg" mt={6} mb={4}>Interpretation and Definitions</Heading>
            <Heading as="h3" size="md" mt={4} mb={2}>Interpretation</Heading>
            <Text mb={4}>
                Words with capitalized initials have specific meanings as defined below. These definitions apply consistently whether they appear in singular or plural form.
            </Text>
            <Heading as="h3" size="md" mt={4} mb={2}>Definitions</Heading>
            <UnorderedList mb={4}>
                <ListItem><strong>Account:</strong> A unique account created for you to access our Service or parts of our Service.</ListItem>
                <ListItem><strong>Company</strong> (referred to as either "the Company", "We", "Us", or "Our" in this Agreement): Refers to Cohuman, 1541 Oxford Street, Berkeley, CA 94709.</ListItem>
                <ListItem><strong>Service:</strong> Refers to the Website.</ListItem>
                <ListItem><strong>Website:</strong> Refers to WhatGF, accessible from <a href="https://whatgf.com/" rel="external nofollow noopener" target="_blank">https://whatgf.com/</a></ListItem>
                <ListItem><strong>You:</strong> Refers to the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</ListItem>
            </UnorderedList>
            <Text mt={4}>
                If you have any questions about this Privacy Policy, you can contact us via email at: cohuman.gang@gmail.com
            </Text>
        </Box>
    );
}

export default Policy;

