import React from "react";

function Roleplay() {
    return (
        <div>
        <h2>Roleplay</h2>
        </div>
    );
}

export default Roleplay;