import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import logo from '../logo.svg';
import '../App.css';
import { SiTelegram } from 'react-icons/si';
import { useEffect } from 'react';
import { sendAmplitudeData, eventType } from '../telemetry';
import { WEB_DESCRIPTION_1, WEB_DESCRIPTION_2, START_CHATTING, WEB_TITLE } from './constants'

export const TELEGRAM_BOT_URL = "https://t.me/DreamGFXXXBot?start=whatgfcom";

function Home() {
  useEffect(() => {
    sendAmplitudeData(eventType.LANDING_PAGE_VIEWED, {});
  }, []);

  // const backgroundColor = "radial-gradient(circle, rgba(255, 0, 0, 0.5) 25%, rgba(255, 192, 203, 0.5) 50%, rgba(64, 64, 64, 0.5) 75%)";
  const backgroundColor = "radial-gradient(circle, red.800 30%, gray.800 75%)";

  return (
    <Box 
      minH="100vh"
      backgroundImage={backgroundColor}
      backgroundBlendMode="overlay"
      textAlign="center"
      fontSize={{ base: "md", md: "xl" }}
    >
      <Box ml={{ base: "2", md: "4" }} mr={{ base: "2", md: "4" }} >
        <Flex justify="space-between" align="center" mb={{ base: 4, md: 8 }} p={{ base: 2, md: 4 }}>
          <Image src={logo} alt="logo" width={{ base: "100px", md: "150px" }} height={{ base: "50px", md: "75px" }} />
        </Flex>
        <Flex p={{ base: 2, md: 3 }} mb={{ base: "25px", md: "50px" }} color="white" direction="column" align="center">
          <Image
            src="/cohuman-landing-image.jpg"
            alt="Premium AI Girlfriend"
            borderRadius="full" 
            boxSize={{ base: "200px", md: "300px" }}
            mb={{ base: "25px", md: "50px" }}
            border="2px solid white"
          />
          <Heading
            as="h1"
            fontSize={{ base: "3xl", md: "5xl" }}
            fontWeight="bold"
            lineHeight="1.2"
            letterSpacing="tight"
            mb="50px"
            textShadow="2px 2px 4px rgba(0,0,0,0.3)"
          >
            {WEB_TITLE}
            <br />
            <span style={{ fontSize: "0.6em", fontStyle: "italic" }}>
              {WEB_DESCRIPTION_1}
            </span>
            <br />
            <span style={{ fontSize: "0.6em", fontStyle: "italic" }}>
              {WEB_DESCRIPTION_2}
            </span>
          </Heading>
          <Button
            as="a"
            href={TELEGRAM_BOT_URL}
            target="_blank"
            rel="noopener noreferrer"
            bgGradient="linear(to-r, pink.500, red.500)"
            color="white"
            size="md"
            width={{ base: "200px", md: "250px" }}
            height={{ base: "50px", md: "75px" }}
            px={{ base: 4, md: 6 }}
            fontSize={{ base: "lg", md: "2xl" }}
            rightIcon={<SiTelegram />}
            _hover={{
              bgGradient: "linear(to-r, pink.400, red.400)", // Slightly lighten the gradient on hover
            }}
            borderRadius="full"
            mb={{ base: "50px", md: "100px" }}
            onClick={() => sendAmplitudeData(eventType.START_CHAT_CLICKED, {})}
          >
            {START_CHATTING}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}

export default Home;