import amplitude from 'amplitude-js';
export const WEB_SOURCE = "WHATGF.COM";

export const eventType = {
  LANDING_PAGE_VIEWED: WEB_SOURCE + "_" + "LANDING_PAGE_VIEWED",
  START_CHAT_CLICKED: WEB_SOURCE + "_" + "START_CHAT_CLICKED",
};

export const initAmplitude = () => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE);
};

export const setAmplitudeUserDevice = installationToken => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = userId => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = properties => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};