import React from "react";

function Profile() {
    return (
        <div>
        <h2>Profile</h2>
        </div>
    );
}

export default Profile;
